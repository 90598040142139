<template>
  <div class="container pd100" v-wechat-title="$route.meta.title=detail.meeting_name">
    <div v-show="!loading">
      <!-- <van-swipe :autoplay="3000" class="swiper" indicator-color="#00de93">
        <van-swipe-item v-for="item in banner" :key="item.id">
          <van-image class="swiper-img" :src="item.image" alt="banner" />
        </van-swipe-item>
      </van-swipe> -->
      <!-- <div class="header"> -->
        <!-- <div class="header-title"> -->
          <!-- {{ detail.meeting_name }}  -->
          <!-- {{ detail.meeting_price }} -->
          <!-- <div class="header-txt">{{ detail.status_name }}</div> -->
        <!-- </div> -->
        <!-- <div class="header-txt"><van-icon name="eye-o" class="mr5" />{{ detail.view_count }}</div> -->
        <!-- <div class="header-txt">{{ detail.status_name }}</div> -->
        <!-- <div class="header-txt ui-red">{{ detail.meeting_price }}</div> -->
      <!-- </div> -->
      <div class="qrcode">
        <div class="qrcode-no">订单号: {{ticketInfo.out_trade_no}}</div>
        <div id="qrcode" class="qrcodeBox" ref="qrcode"></div>
      </div>
      <a :href="'tel:'+detail.meeting_hotline" style="display:block" class="van-hairline--bottom">
        <van-cell
          :title="detail.meeting_hotline"
          icon="phone-o"
          is-link
        />
      </a>
      <van-cell v-if="detail.meeting_qywx_id" title="企业微信咨询" icon="comment-o" is-link :to="`/namecard?qywx_id=${detail.meeting_qywx_id}`" />
      <van-cell :title="detail.meeting_address" icon="location-o" />
      <van-cell :title="detail.meeting_price" icon="gold-coin-o" />
      <van-cell class="mb10" :title="detail.meeting_start_date + ' - ' + detail.meeting_end_date" icon="clock-o" />

      <van-cell title="订单信息" />
        <div class="info bgf">
          <div class="info-item" v-if="ticketInfo.ticket_book_name">
            学员姓名：{{ ticketInfo.ticket_book_name }}
          </div>
          <div class="info-item" v-if="ticketInfo.ticket_book_cellphone">
            手机号码：{{ ticketInfo.ticket_book_cellphone }}
          </div>
          <!-- <div class="info-item">
            订单号码：{{ ticketInfo.out_trade_no }}
          </div> -->
          <div class="info-item">
            票据类型：{{ ticketInfo.ticket_name }}
          </div>
          <div class="info-item">
            票据代码：{{ ticketInfo.ticket_code }}
          </div>
          <div class="info-item">
            票据状态：{{ ticketInfo.ticket_check?'已签到':'未使用' }}
          </div>
          <div class="info-item">
            票据个数：{{ ticketInfo.ticket_count }}
          </div>
          <div class="total ui-red ui-right">
            实付：￥{{ ticketInfo.ticket_price }}
          </div>
        </div>
    </div>
  </div>
</template>
<script>

import PageMixin from '@/mixins/page'
import { Swipe, SwipeItem } from 'vant'
import QRCode from 'qrcodejs2'

export default {
  name: 'EventsTicket',
  components: {
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem
  },
  mixins: [PageMixin],
  data() {
    return {
      banner: [],
      detail: {},
      ticketInfo: {},
      loading:false,
    }
  },
  created() {
    this.loading = true
  },
  mounted(){
    this.onDetail()
  },
  methods: {
    onDetail() {
      this.$toast.loading({
        mask: true,
        message: '加载中...',
        duration: 0
      })
      this.$api.events_ticket({ ticket_id: this.$route.query.ticket_id }).then(res => {
        this.$toast.clear()
        this.banner = [{ id: '111', image: res.data.meeting_cover }]
        this.detail = res.data
        this.ticketInfo = res.data.meeting_ticket
        this.$nextTick(() => {
          var canvas = this.$refs.qrcode
          new QRCode(canvas, {
            text:res.data.meeting_ticket.ticket_code, 
            width:200,
            height:200,
            colorDark : res.data.meeting_ticket.ticket_check === 0?"#00cca2":"#000",   //二维码颜色
            colorLight : "#ffffff"   //二维码背景色
          })
        })
        this.loading = false
      })
    }
  }
}
</script>

<style lang="less" scoped>

  .swiper {
    &-img {
        width: 100%;
        height: 180px;
    }
  }
  .header{
    background: #fff;
    margin-bottom: 10px;
    box-sizing: border-box;
    width: 100%;
    padding: 0 15PX;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    &-title{
        flex: 1;
        font-size: 16px;
        font-weight: bold;
        columns: #333;
    }
    &-txt{
        color: #999;
        font-size: 12px;
        display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }
  .info{
    padding: 10px 15px;
  }
  .info-item{
    margin-bottom: 10rpx;
  }
  .qrcode{
    background: #fff;
    margin-bottom: 10px;
    padding: 10px 0;
    &-no{
      text-align: center;
      margin-bottom: 10px;
    }
  }
  .qrcodeBox{
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>

